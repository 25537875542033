<template>
  <div class="dg-module-form form-section mb-0" v-if="amReady">
    <div class="dg-module-container text-capitalize mb-2" v-if="formHeader">
      <h5>{{ this.getFormTitle }}</h5>
    </div>

    <div class="form-section">
      <div class="sub-title">Basic Information</div>
      <el-row :gutter="10" class="mt-2">
        <el-col :class="$langConfig.cssClass" :md="8" :sm="12" :xs="24">
          <dg-text-field
              :control="control"
              :editable="editable"
              :field_span="24"
              :label_span="24"
              :translateLabel='false'
              field_mark="required"
              label="name"
              name="name"
              placeholder="Name"
              rules="required"></dg-text-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :md="8" :sm="12" :xs="24">
          <dg-select-field
              :control="control"
              :editable="editable"
              :field_span="24"
              :label_span="24"
              :options="$store.state['Office-Supplier-api'].dropDownList"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Supplier"
              name="supplier_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 220px;" >
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="24"
              :label_span="24"
              :translateLabel='false'
              :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
              @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
              field_mark="required"
              maxLength="12"
              label="Price ($)"
              name="price"
              placeholder="e.g 12,000"
              rules="required"></dg-text-field>
        </el-col>
      </el-row>

      <el-row class="mt-3">
        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24">
          <dg-text-field
              :control="control" :disabled="control.ctrlsDisabled" :editable="control.editable"
              :field_span="24"
              :label_span="24"
              :translateLabel='false'
              field_mark="optional"
              inputPrepend="RQ Requirements"
              label="Description"
              name="description"
              placeholder=""
              rules=""
              type="textarea"></dg-text-field>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="10">
      <el-col :span="24" class="mt-2 border-top">
        <el-col :class="$langConfig.cssClass" :span="12" class="mt-4">
          <label class="error-field-validate-message text-capitalize"
                 v-show="!this.validData">{{
              (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria')
            }}</label>
        </el-col>
        <el-col :span="12" class="pt-3 text-right">
          <dg-confirm-button
              :loading="requestOn"
              :onConfirm="moduleDeleteAction"
              :translate="false"
              confirmButtonType="danger"
              fieldSize="mini"
              icon="el-icon-delete"
              popIcon="el-icon-delete"
              style="margin-right: 10px"
              text="remove"
              title="sure to removing this record ?"
              type="danger"
              v-if="editMode"></dg-confirm-button>

          <dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                     type="warning" v-if="editable && editMode"></dg-button>
          <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                     v-if="!editable && editMode"></dg-button>

          <dg-button :click="createModule" :icon="actionButtonIcon" :loading="requestOn" :text="actionButtonLabel"
                     :translate="false"
                     :type="actionButtonType" fieldSize="mini"
                     v-if="editable"></dg-button>

          <!--<dg-confirm-button
              :icon="actionButtonIcon"
              :loading="requestOn"
              :onConfirm="createModule"
              :text="actionButtonLabel"
              :translate="false"
              :type="actionButtonType"
              fieldSize="mini"
              v-if="editable"></dg-confirm-button>-->
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import module from './../index.js'

export default {
  beforeMount() {
    this.getFormData()
  },
  data() {
    return {
      module: {...module},
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      requestErrorMessage: null,
      radioOptions: [
        {
          label: 'Yes',
          value: 1,
          link_label: ''
        },
        {
          label: 'No',
          value: 0,
          link_label: ''
        }
      ],
    }
  },
  methods: {
    getFormData() {
      let promises = []
      promises.push($xapi.getOfficeSupplierDropdown().then())
      Promise.all(promises).then((values) => {
        this.amReady = true
      })
    },
    ...$mapActions(module.info.fullName + '-api', {
      userDataAuthFields: 'userDataAuthFields',
      getEntityDropDownList: 'getDropDownList',
      /*
      updateFile: 'updateFile',
      updateGallery: 'updateGallery',
      removeGalleryImage: 'removeGalleryImage'*/
    }),
    /* removeFromGallery (file) {
      let params = {
        id: this.dataToBind.id,
        fileId: file.id,
        parentId: file.parent_id
      }
      this.removeGalleryImage(params).then(r => {
          if (this.onEditCallback) {
            this.onEditCallback(r.data, 'gallery', false)
          }
          $services['notify']['success']('Picture Removed')
      })
    },
    async uploadFile (entityId, fieldName, apiName) {
      if (this.dataModel.hasOwnProperty(fieldName) && this.dataModel[fieldName]) {
        let data =  {}
        data[fieldName] = this.dataModel[fieldName]

        let fileParams = {data, id: entityId}
        await this[apiName](fileParams).then(r => {
          $services['notify']['success']((apiMethod == 'update') ? 'Updated' : 'Created')
        }).catch(error => {
          $services['notify']['danger']('File Not Uploaded')
        })
      }
    },
    async uploadGallery (entityId) {
      if (this.dataModel.hasOwnProperty('gallery') && this.dataModel.gallery) {
        let galleryParams = {
          data: {
            gallery: this.dataModel.gallery
          },
          id: entityId
        }

        await this.updateGallery(galleryParams).then(r => {
          $services['notify']['success']('Gallery Uploaded')
        }).catch(error => {
          $services['notify']['danger']('Gallery Not Uploaded')
        })
      }
    },*/
    async createModule() {
      this.validateFields().then(async result => {
        if (!result) {
          this.requestErrorMessage = null;
          return
        }

        this.requestOn = true
        let context = this;
        let apiMethod = (this.editMode) ? 'update' : 'create';
        let apiParams = {data: {...this.dataModel}};
        if (this.editMode) apiParams.id = this.dataToBind.id;

        $store.dispatch(this.module.info.fullName + '-api/' + apiMethod, apiParams).then(async response => {
          let entity = response.data
          this.amReady = false
          $services['notify']['success']((apiMethod == 'update') ? 'Updated' : 'Created')


          $vue.nextTick(() => {
            context.amReady = true
            if (context.editMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }
          })

        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {
          this.requestOn = false
        })
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
